import React, { useState } from 'react'
import ContentContext from './ContentContext'

const ContextProvider = (props) => {
    const [state, setState] = useState({ lng: `#lng@el`, mkt: `#mkt@greece`, sct: `#sct@general` })
    //default settings will came from tag management component
    return (
        <ContentContext.Provider {...props} value={[state, setState]}>
            {props.children}
        </ContentContext.Provider>
    )
}

export default ContextProvider
